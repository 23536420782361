import { Grid, styled, Button } from "@mui/material";
import InputFields from "./InputFields";
import { useState } from "react";
import { useAuthenticateAdminMutation } from "state/api";
import { useDispatch } from "react-redux";
import { setIsTokenVerifed, setTokens } from "state";

function Authenticate() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [authenticateAdmin] = useAuthenticateAdminMutation();

  const check = (str) => {
    if (!str) return true;
    if (String(str).trim().length === 0) {
      return true;
    }
    return false;
  };

  const handleLogin = async () => {
    if (isLoading || check(email) || check(password)) return;

    try {
      setIsLoading(true);
      const res = await authenticateAdmin({ email, password });
      const data = res.data;
      if (data?.refreshToken) {
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("accessToken", data.accessToken);
        dispatch(setIsTokenVerifed(true));

        dispatch(
          setTokens({
            accessToken: data?.accessToken,
            refreshToken: data?.refreshToken,
          })
        );
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      dispatch(setIsTokenVerifed(false));

      console.log(e);
    }
  };
  return (
    <GridStyled
      sx={{
        minHeight: "500px",
        marginY: "2rem",
        marginX: "auto",
      }}
      container
      justifyContent={"center"}
      rowGap={5}
    >
      <Grid container alignItems={"center"} rowGap={1}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <h1>Login to Account</h1>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <span>Login to access KingSorat Admin Dashboard</span>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {/* Form */}
        <Grid
          rowGap={4}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={9}>
            <Grid
              container
              rowGap={4}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item xs={12}>
                <InputFields
                  label={"Email"}
                  required={true}
                  placeholder={"Enter Your Email"}
                  type={"email"}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  value={email}
                />
              </Grid>
              <Grid item xs={12}>
                <InputFields
                  required={true}
                  label={"Password"}
                  placeholder={"Enter Your Password"}
                  type={"password"}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  value={password}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={11}>
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={8}>
                <FlexBox>
                  <SignupBtn
                    onClick={handleLogin}
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    Login to Account
                  </SignupBtn>
                </FlexBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GridStyled>
  );
}

export default Authenticate;

// Styles

const FlexBox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
const GridStyled = styled(Grid)(({ theme }) => ({
  "@keyframes animate": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  animation: "animate .3s linear forwards",
}));

export const SignupBtn = styled(Button)(({ theme }) => ({
  paddingBlock: "12px",
  fontSize: "1.1rem",
  backgroundColor: "#098E6E",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#098E6E",
  },
}));
