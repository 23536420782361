import React, { useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  useTheme,
  useMediaQuery,
  Stack,
} from "@mui/material";
import Header from "components/Header";
import { useGetHousesQuery } from "state/api";
import ModalWrapper from "components/ModaWrpper";
import StatusForm from "./StatusForm";
import { SignupBtn } from "scenes/Authenticate";
import HousForm from "./HousForm";
import EditNoteSharpIcon from "@mui/icons-material/EditNoteSharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
const House = ({
  name = "",
  users = "",
  price = "",
  category = "",
  _id,
  setOpen,
  setSelectedHouse,
}) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
      }}
      onClick={() => {
        return;
        setOpen("house");
      }}
    >
      <CardHeader
        sx={{ color: "white", paddingBlockEnd: "0px" }}
        title={
          <Stack justifyContent={"flex-end"} direction={"row"} gap={"5px"}>
            <EditNoteSharpIcon
              sx={{
                width: "1.8rem",
                height: "1.8rem",
                cursor: "pointer",
                color: "#16d6e6",
              }}
              onClick={() => {
                setOpen("houseEdit");
                setSelectedHouse(_id);
              }}
            />
            <DeleteSharpIcon
              sx={{
                width: "1.55rem",
                height: "1.55rem",
                cursor: "pointer",
                color: "#da2121",
              }}
              onClick={() => {
                setOpen("houseDelete");
                setSelectedHouse(_id);
              }}
            />
          </Stack>
        }
      />
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color={theme.palette.secondary[700]}
          gutterBottom
        >
          {category}
        </Typography>
        <Typography variant="h5" component="div">
          {name}
        </Typography>
        <Typography sx={{ mb: "1rem" }} color={theme.palette.secondary[400]}>
          {Number(price)}
        </Typography>

        <Typography variant="body2">{"users : " + users?.length}</Typography>
      </CardContent>
    </Card>
  );
};
const Stats = ({ name = "", startDate, endDate, active, setOpen }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
        cursor: "pointer",
      }}
      onClick={() => {
        setOpen("status");
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div" sx={{ mb: "1rem" }}>
          {name}
        </Typography>
        <Typography color={theme.palette.secondary[400]}>
          start Date: {startDate}
        </Typography>
        <Typography color={theme.palette.secondary[400]}>
          end Date: {endDate}
        </Typography>
        <Typography sx={{ mb: "1rem" }} color={theme.palette.secondary[400]}>
          active: {active ? active : false}
        </Typography>
      </CardContent>
    </Card>
  );
};

const Houses = () => {
  const { data, isLoading } = useGetHousesQuery();
  const result = data?.result;
  const [open, setOpen] = useState(false);
  const [selectedHouse, setSelectedHouse] = useState("");
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  if (isLoading) return null;
  const condition =
    open === "houseEdit" || open === "houseDelete" || open === "house";
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Houses" subtitle="See your list of Houses." />

      <Stack
        justifyContent={"flex-end"}
        sx={{ width: "100%", my: 1 }}
        direction={"row"}
      >
        <SignupBtn
          sx={{
            color: "white",
            maxWidth: "200px",
            minWidth: "160px",
            fontSize: "1rem",
          }}
          onClick={() => {
            setOpen("house");
          }}
        >
          create house
        </SignupBtn>
      </Stack>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          opacity: isLoading ? 0 : 1,
          userSelect: isLoading ? "none" : undefined,
        }}
      >
        {/* <Stats
          name="Stats"
          startDate={new Date(result?.startDate).toString()}
          endDate={new Date(result?.startDate).toString()}
          active={`${result?.active}`}
          setOpen={setOpen}
        /> */}
      </Box>
      {data || !isLoading ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {result?.map(({ _id, HouseName, TotalBetAmount, users }) => (
            <House
              key={_id}
              _id={_id}
              name={HouseName}
              users={users}
              price={TotalBetAmount}
              setOpen={setOpen}
              setSelectedHouse={setSelectedHouse}
            />
          ))}
        </Box>
      ) : (
        <>Loading...</>
      )}
      <ModalWrapper open={open} setOpen={setOpen}>
        {open === "status" && <StatusForm houses={result} setOpen={setOpen} />}

        {condition && (
          <HousForm
            open={open}
            selectedHouse={selectedHouse}
            setSelectedHouse={setSelectedHouse}
            houses={result}
            setOpen={setOpen}
          />
        )}
      </ModalWrapper>
    </Box>
  );
};

export default Houses;
