import { Modal, Box, Fade } from "@mui/material";

export default function ModalWrapper({ open, setOpen = () => {}, children }) {
  const handleClose = () => setOpen(false);
  return (
    <Modal
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      aria-labelledby="admin modal"
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} alignItems="center">
          {children}
        </Box>
      </Fade>
    </Modal>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  color: "black",
  border: "2px solid transparent",
  boxShadow: 24,
  p: 4,
};
