import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "dark",
  userId: "65957a68ae73ac2ece5f63f6",
  tokens: {
    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
    tokenVerified: false,
  },
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setTokens: (state, action) => {
      const acc = action.payload.refreshToken;
      state.tokens.accessToken = action.payload.accessToken;
      state.tokens.refreshToken = action.payload.refreshToken;
      if (acc !== " " && acc) {
        localStorage.setItem("accessToken", action.payload.accessToken);
        localStorage.setItem("refreshToken", action.payload.refreshToken);
      } else {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      }
    },
    setIsTokenVerifed: (state, action) => {
      state.tokens.tokenVerified = action.payload;
    },
  },
});

export const { setMode, setTokens, setIsTokenVerifed } = globalSlice.actions;

export default globalSlice.reducer;
