import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Avatar,
  IconButton,
  Grid,
  Modal,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { ChatBubbleOutline } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import Header from "components/Header";
import { DateRange } from "react-date-range";
import { parseISO } from "date-fns";
import {
  useGetSupportInfoQuery,
  useUpdateSupportMessageMutation,
} from "state/api";
import { convertTimestampToDateAndTime } from "state/functions";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const ChatModal = ({ open, handleClose, chatData, handleSendMessage }) => {
  const theme = useTheme();
  const [newMessage, setNewMessage] = useState("");

  const handleSend = () => {
    if (newMessage.trim()) {
      handleSendMessage(newMessage);
      setNewMessage("");
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "50%",
          height: "70%",
          margin: "auto",
          marginTop: "5%",
          padding: "20px",
          backgroundColor: theme.palette.background.default,
          borderRadius: "10px",
          overflowY: "scroll",
        }}
      >
        <Typography variant="h6" mb={2}>
          Chat with {chatData?.userId?.username}
        </Typography>
        <Box mb={2}>
          {chatData?.messages?.map((msg, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent:
                  msg.sender === "admin" ? "flex-end" : "flex-start",
                mb: 1,
              }}
            >
              <Box
                sx={{
                  padding: "10px",
                  borderRadius: "10px",
                  backgroundColor:
                    msg.sender === "admin"
                      ? theme.palette.primary.light
                      : "#585858",
                  maxWidth: "70%",
                }}
              >
                <Typography variant="body2">{msg.text}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {convertTimestampToDateAndTime(msg.timestamp)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <TextField
          fullWidth
          placeholder="Type your message here..."
          variant="outlined"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSend}
        >
          Send
        </Button>
      </Box>
    </Modal>
  );
};

const Stats = ({ data, setOpenChat, setChatData }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
        cursor: "pointer",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Avatar sx={{ mr: 2 }}>{data?.userId?.username.charAt(0)}</Avatar>
              <Box>
                <Typography variant="h5" component="div" sx={{ mb: "1rem" }}>
                  {data?.userId?.username}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {convertTimestampToDateAndTime(data?.createdAt)}
                </Typography>
              </Box>
              <IconButton
                sx={{ position: "absolute", right: 16, top: 16 }}
                onClick={() => {
                  setOpenChat(true);
                  setChatData({
                    ...data,
                    messages: JSON.parse(data.messages) || [],
                  });
                }}
              >
                <ChatBubbleOutline />
              </IconButton>
            </Box>
            <Typography variant="h6" component="div" sx={{ mt: "1rem" }}>
              Game: {data?.game}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ mt: "0.5rem" }}
            >
              {data?.description}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Support = () => {
  const { data, isLoading, refetch } = useGetSupportInfoQuery();
  const [updateSupportMessage] = useUpdateSupportMessageMutation();
  const [openChat, setOpenChat] = useState(false);
  const [chatData, setChatData] = useState();
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  const filteredResults = data?.data?.filter((item) => {
    if (!dateRange[0].startDate || !dateRange[0].endDate) return true;

    const createdAt = parseISO(item.createdAt);
    const endDate = new Date(dateRange[0].endDate);
    endDate.setDate(endDate.getDate() + 1); // Add one day to the end date

    return createdAt >= dateRange[0].startDate && createdAt < endDate;
  });

  const handleSendMessage = async (newMessage) => {
    const timestamp = new Date().toISOString();

    const currentMessages = Array.isArray(chatData.messages)
      ? chatData.messages
      : [];

    const updatedMessages = [
      ...currentMessages,
      { text: newMessage, sender: "admin", timestamp },
    ];

    try {
      await updateSupportMessage({
        id: chatData._id,
        newMessage: { text: newMessage, sender: "admin", timestamp },
      });

      setChatData((prevChatData) => ({
        ...prevChatData,
        messages: updatedMessages,
      }));

      refetch();
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  if (isLoading) return null;

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Support" subtitle="List of Support Messages" />
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        position="relative"
      >
        <Button
          variant="contained"
          onClick={() => setIsDateRangeOpen(!isDateRangeOpen)}
        >
          {isDateRangeOpen
            ? "Close Date Range Picker"
            : "Open Date Range Picker"}
        </Button>
        {isDateRangeOpen && (
          <Box
            position="absolute"
            top="100%"
            right="0"
            zIndex="1"
            boxShadow={3}
            bgcolor="background.paper"
          >
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              maxDate={new Date()}
            />
          </Box>
        )}
      </Box>
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          opacity: isLoading ? 0 : 1,
          userSelect: isLoading ? "none" : undefined,
        }}
      >
        {filteredResults?.map((item) => (
          <Stats
            key={item._id}
            data={item}
            setOpenChat={setOpenChat}
            setChatData={setChatData}
          />
        ))}
      </Box>

      {chatData && (
        <ChatModal
          open={openChat}
          handleClose={() => setOpenChat(false)}
          chatData={chatData}
          handleSendMessage={handleSendMessage}
        />
      )}
    </Box>
  );
};

export default Support;
