import React, { useMemo, useState } from "react";
import { TextField, Stack, styled, FormLabel } from "@mui/material";

import { SignupBtn } from "scenes/Authenticate";
import {
  useCreatehousesMutation,
  useDeletehouseMutation,
  useEdithouseMutation,
} from "state/api";

const LabelForInput = styled(FormLabel)(({ theme }) => ({
  color: "black",
  fontSize: "1.2rem",
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
}));

const TextStyled = styled(TextField)(({ theme }) => ({
  color: "black",
  "& input, svg": {
    borderColor: "black",
    color: "black",
  },
  "fieldset,  fieldset:hover": {
    borderColor: "black !important",
  },
}));

const HousForm = ({
  houses,
  setOpen,
  open,
  selectedHouse,
  setSelectedHouse,
}) => {
  const house = useMemo(
    () => houses?.find((e) => e?._id === selectedHouse),
    [selectedHouse]
  );
  const [formData, setFormData] = useState({ HouseName: "" });
  const [HouseName, setHouseName] = useState(house?.HouseName || "");

  const [createhouses, { isLoading }] = useCreatehousesMutation();
  const [deletehouse, { isLoading: loadingDelete }] = useDeletehouseMutation();
  const [edithouse, { isLoading: loadingEdit }] = useEdithouseMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (isLoading) return;
      const HouseName = formData.HouseName;
      if (!HouseName || HouseName?.length === 0) {
        return;
      }

      const data = await createhouses({ houses: [{ HouseName }] });
      console.log(data);
      setOpen(false);
    } catch (e) {
      console.log("Error");
      setOpen(true);
    }
  };
  const handleEdit = async (event) => {
    event.preventDefault();
    try {
      if (loadingEdit) return;
      if (!HouseName || HouseName?.length === 0) {
        return;
      }

      const data = await edithouse({ HouseName, houseId: house?._id });
      console.log(data);
      setOpen(false);
    } catch (e) {
      console.log("Error");
      setOpen(true);
    }
  };
  const handleDelete = async (event) => {
    event.preventDefault();
    try {
      if (loadingDelete) return;
      if (!house?._id) {
        return;
      }

      const data = await deletehouse({ houseId: house._id });
      console.log(data);
      setOpen(false);
    } catch (e) {
      console.log("Error");
      setOpen(true);
    }
  };

  return open === "house" ? (
    <form>
      <Stack spacing={2} direction="column" sx={{ bg: "black" }}>
        <LabelForInput>House Name</LabelForInput>
        <TextStyled
          value={formData.HouseName}
          onChange={(e) => setFormData({ HouseName: e.target.value })}
        />
        <SignupBtn
          type="submit"
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Submit
        </SignupBtn>
      </Stack>
    </form>
  ) : open === "houseDelete" ? (
    <Stack spacing={2} direction="column" sx={{ bg: "black" }}>
      <LabelForInput>
        Are you Sure you want to delete the house `{house?.HouseName}
        `?{" "}
      </LabelForInput>

      <SignupBtn type="submit" onClick={handleDelete} variant="contained">
        Delete{" "}
      </SignupBtn>
    </Stack>
  ) : open === "houseEdit" ? (
    <form>
      <Stack spacing={2} direction="column" sx={{ bg: "black" }}>
        <LabelForInput>Edit House Name</LabelForInput>
        <TextStyled
          defaultValue={house?.HouseName}
          value={HouseName}
          onChange={(e) => setHouseName(e.target.value)}
        />
        <SignupBtn
          type="submit"
          onClick={handleEdit}
          variant="contained"
          color="primary"
        >
          Edit House
        </SignupBtn>
      </Stack>
    </form>
  ) : null;
};

export default HousForm;
