import React, { useState } from "react";
import {
  Stack,
  styled,
  FormLabel,
  TextField,
  MenuItem,
  Select,
  FormControl,
  SelectProps,
} from "@mui/material";

import { SignupBtn } from "scenes/Authenticate";
import { useUpdateTimersMutation } from "state/api";
import { Bounce, toast } from "react-toastify";

const LabelForInput = styled(FormLabel)(({ theme }) => ({
  color: "black",
  fontSize: "1.2rem",
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
}));

const TextStyled = styled(TextField)(({ theme }) => ({
  width: "100%",
  color: "black",
  "& input, svg": {
    borderColor: "black",
    color: "black",
  },
  "& fieldset, & fieldset:hover": {
    borderColor: "black !important",
  },
}));

// Style the Select component
const BlackSelect = styled(Select)(({ theme }) => ({
  color: "black",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
  "& .MuiSvgIcon-root": {
    // Targeting the dropdown icon specifically
    color: "black",
  },
}));

const TimersForm = ({ setOpen, open, data }) => {
  const { _id, titliTimer, centercoinTimer, gameType, updatedAt, ...rest } =
    data;
  const [formData, setFormData] = useState({ titliTimer, ...rest });
  const [updateTimers, { isLoading }] = useUpdateTimersMutation();

  const handleEdit = async (event) => {
    event.preventDefault();
    if (isLoading) return;

    try {
      if (formData?.fakePlayers === "") {
        return toast.error("Enter fake players correctly", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }
      await updateTimers({ ...formData, id: _id });
      setOpen(false);
    } catch (e) {
      console.log("Error");
      setOpen(true);
    }
  };

  return (
    <form onSubmit={handleEdit}>
      <Stack spacing={2} direction="column" sx={{ bg: "black" }}>
        <LabelForInput>Edit Game Settings</LabelForInput>
        <div style={{ width: "100%" }}>
          <p>Titli Timer</p>
          <FormControl fullWidth>
            <BlackSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData.titliTimer}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  titliTimer: parseInt(e.target.value),
                })
              }
              fullWidth
            >
              {[60, 120, 180, 240, 300].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </BlackSelect>
          </FormControl>
        </div>
        {Object.keys(rest).map((entry, ind) => (
          <div key={ind} style={{ width: "100%" }}>
            <p style={{ marginTop: "0px" }}>Fake Players</p>
            <TextStyled
              value={formData[entry]}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  [entry]: isNaN(e.target.value) ? "" : e.target.value,
                }));
              }}
            />
          </div>
        ))}
        <SignupBtn type="submit" variant="contained" color="primary">
          Edit Settings
        </SignupBtn>
      </Stack>
    </form>
  );
};

export default TimersForm;
