import React, { Fragment, useState } from "react";
import {
  Box,
  useTheme,
  MenuItem,
  Menu,
  Button,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { MoreVert, Download } from "@mui/icons-material";
import { DateRange } from "react-date-range";
import { parseISO } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import * as XLSX from "xlsx";
import Header from "components/Header";
import {
  useGetUsersQuery,
  useToggleUserStautsMutation,
  useDeleteUserMutation,
} from "state/api";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const Users = () => {
  const theme = useTheme();
  const { data, isLoading, refetch } = useGetUsersQuery();
  const result = data?.result || [];
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState(null);
  const [user, setUser] = useState(null);
  const [deleteUser, setDeleteUser] = useState(null);
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
  const open = Boolean(anchorEl);

  const [toggleUserStauts, { isLoading: loadingStatus }] =
    useToggleUserStautsMutation();
  const [deleteUserMutation, { isLoading: deletingUser }] =
    useDeleteUserMutation();

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setStatus(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatus = async () => {
    try {
      await toggleUserStauts({ _id: status });
      refetch();
    } catch (e) {
      console.log(e);
    }
    handleClose();
  };

  const handleDelete = () => {
    const userToDelete = result?.find((user) => user?._id === status);
    setDeleteUser(userToDelete);
    handleClose();
  };

  const confirmDelete = async () => {
    try {
      await deleteUserMutation({ id: deleteUser._id });
      setDeleteUser(null);
      refetch();
    } catch (e) {
      console.log(e);
    }
  };

  const filteredResults = result.filter((user) => {
    if (!dateRange[0].startDate || !dateRange[0].endDate) return true;
    const createdAt = parseISO(user.createdAt);
    const endDate = new Date(dateRange[0].endDate);
    endDate.setDate(endDate.getDate() + 1);

    return createdAt >= dateRange[0].startDate && createdAt < endDate;
  });

  const exportToCSV = () => {
    const dataToExport = filteredResults.map((user) => ({
      ID: user._id,
      Username: user.username,
      DOB: user.dob,
      Email: user.email,
      Mobile: user.number,
      Status: user.hidden ? "Blocked" : "Active",
      CoinBalance: user.userCoins,
      CoinsAdded: user.coinsAdded,
      CoinsWithdrawn: user.coinsWithdrawn,
      TotalCoinsWon: user.totalCoinsWon,
      TotalCoinsLost: user.totalCoinsLost,
      LastLoggedIn: user.lastLoggedIn
        ? new Date(user.lastLoggedIn).toLocaleString()
        : "-",
      TotalBets: user.totalBets,
      RegistrationDate: new Date(user.createdAt).toDateString(),
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.writeFile(
      workbook,
      `UsersData_${new Date().toLocaleDateString()}.csv`
    );
  };

  const columns = [
    {
      field: "_id",
      headerName: "Player ID",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "username",
      headerName: "Player Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "dob",
      headerName: "DOB",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row?.number || " - "}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row?.hidden ? "Blocked" : "Active"}
          </div>
        );
      },
    },
    {
      field: "userCoins",
      headerName: "Coin Balance",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "coinsAdded",
      headerName: "Coins Added",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "coinsWithdrawn",
      headerName: "Coins Withdrawn",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "totalCoinsWon",
      headerName: "Total Coins Won",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "totalCoinsLost",
      headerName: "Total Coins Lost",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "lastLoggedIn",
      headerName: "Last LoggedIn",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const value = params.value;
        const displayValue = value ? new Date(value).toLocaleString() : "-";
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {displayValue}
          </div>
        );
      },
    },
    {
      field: "totalBets",
      headerName: "Total Bets",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "createdAt",
      headerName: "Registration Date",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {new Date(params.value).toDateString()}
          </div>
        );
      },
    },
    {
      field: " ",
      headerName: "Edit",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <IconButton onClick={(e) => handleClick(e, params.row._id)}>
            <MoreVert sx={{ cursor: "pointer" }} />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Box
        m="1.5rem 2.5rem"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <Header title="Users" subtitle="List of Users" />
        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            startIcon={<Download />}
            onClick={exportToCSV}
          >
            Export CSV
          </Button>
          <Button
            variant="contained"
            onClick={() => setIsDateRangeOpen(!isDateRangeOpen)}
          >
            {isDateRangeOpen
              ? "Close Date Range Picker"
              : "Open Date Range Picker"}
          </Button>
        </Box>
        {isDateRangeOpen && (
          <Box
            position="absolute"
            top="100%"
            right="0"
            zIndex="1"
            boxShadow={3}
            bgcolor="background.paper"
          >
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              maxDate={new Date()}
            />
          </Box>
        )}
      </Box>
      <Box
        mt="40px"
        height="75vh"
        width="100%"
        sx={{
          overflowX: "auto",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
        m="1.5rem 2.5rem"
      >
        <DataGrid
          loading={isLoading || loadingStatus || deletingUser}
          getRowId={(row) => row._id}
          rows={filteredResults}
          columns={columns}
          isRowSelectable={false}
          disableSelectionOnClick
          autoHeight
        />
      </Box>

      <CustomMenu
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        handleStatus={handleStatus}
        setUser={setUser}
        handleDelete={handleDelete}
      />

      {deleteUser && (
        <DeleteConfirmationModal
          open={Boolean(deleteUser)}
          handleClose={() => setDeleteUser(null)}
          handleConfirm={confirmDelete}
        />
      )}
    </Fragment>
  );
};

export default Users;

const CustomMenu = ({
  anchorEl,
  onClick,
  open,
  handleStatus,
  setUser,
  handleDelete,
}) => {
  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClick}>
      <MenuItem onClick={handleStatus}>Block User</MenuItem>
      <MenuItem onClick={setUser}>Update Coins</MenuItem>
      <MenuItem onClick={handleDelete}>Delete User</MenuItem>
    </Menu>
  );
};

// Delete Confirmation Modal
const DeleteConfirmationModal = ({ open, handleClose, handleConfirm }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this user? This action cannot be
          undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
