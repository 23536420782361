import React from "react";
import { Stack } from "@mui/material";
const PageNotFound = () => {
  return (
    <Stack
      justifyContent={"center"}
      alignItems="center"
      sx={{
        minHeight: "400px",
        fontSize: "1.4rem",
        fontWeight: "600",
        padding: "2rem",

        "@media screen and (max-width: 600px)": {
          // minHeight: "400px",
        },
      }}
    >
      Page Not Found
    </Stack>
  );
};

export default PageNotFound;
