import {
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
const LabelForInput = styled(FormLabel)(({ theme }) => ({
  color: "black",
  fontSize: "1.2rem",
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
}));
function InputFields({
  name,
  type,
  placeholder,
  label,
  required,
  rows,
  onBlur,
  onChange,
  error,
  helperText,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  console.log();
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Grid container rowGap={1}>
      <Grid item xs={12}>
        <LabelForInput
          color="error"
          required={required ? true : false}
          style={{ color: "white" }}
          className="emailInputLabel"
        >
          {label}
        </LabelForInput>
      </Grid>

      {type === "email" && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            id={type}
            name={type}
            // type={type}
            type="email"
            required
            placeholder={placeholder}
            error={error}
            onBlur={onBlur}
            onChange={onChange}
            helperText={helperText}
          />
        </Grid>
      )}

      {type === "password" && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            name={"password"}
            placeholder={placeholder}
            onChange={onChange}
            variant="outlined"
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      )}
    </Grid>
  );
}

export default InputFields;
