import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const base = process.env.REACT_APP_BASE_URL + "/";
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: base + "api/private/admin/",
    // Add headers to the requests
    prepareHeaders: (headers, { getState }) => {
      // Add any headers you need here
      const token = getState().global.tokens?.accessToken;
      headers.set("Authorization", `Bearer ${token}`);
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Users",
    "Admins",
    "Transactions",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
    "Houses",
    "tokenVerified",
    "history",
    "timers",
    "games",
    "gamesessions",
  ],
  endpoints: (build) => ({
    // getUser: build.query({
    //   query: (id) => `general/user/${id}`,
    //   providesTags: ["User"],
    // }),
    getUsers: build.query({
      query: (id) => `user/listusers`,
      providesTags: ["Users"],
    }),
    getUsersAddRequests: build.query({
      query: (id) => `user/listAddCoinRequests`,
      providesTags: ["UsersCoins"],
    }),
    getUsersPayoutRequests: build.query({
      query: (id) => `user/listPayoutCoinRequests`,
      providesTags: ["UsersCoins"],
    }),
    getSessions: build.query({
      query: (id) => `game/gameAllusersession`,
      providesTags: ["games"],
    }),
    getSupportInfo: build.query({
      query: (id) => `game/getsupportinfo`,
      providesTags: ["games"],
    }),
    getProducts: build.query({
      query: () => "admin/products",
      providesTags: ["Products"],
    }),
    getHouses: build.query({
      query: () => "house/gethouse",
      providesTags: ["Houses"],
    }),
    getAdmins: build.query({
      query: () => "admin/listadmins",
      providesTags: ["Admins"],
    }),
    getTransactions: build.query({
      query: () => ({
        url: "game/gameAlltransactions",
        method: "GET",
      }),
      providesTags: ["Transactions"],
    }),

    getSales: build.query({
      query: () => "sales/sales",
      providesTags: ["Sales"],
    }),
    getGames: build.query({
      query: () => "admin/games",
      providesTags: ["games"],
    }),
    getHistory: build.query({
      query: () => "house/gethistory",
      providesTags: ["history"],
    }),

    getDashboard: build.query({
      query: ({ startDate, endDate } = {}) =>
        `admin/dashboard${
          startDate && endDate
            ? `?startDate=${startDate}&endDate=${endDate}`
            : ""
        }`,
      providesTags: ["Dashboard"],
    }),
    getTimers: build.query({
      query: () => "game/timers",
      providesTags: ["timers"],
    }),

    // post
    authenticateAdmin: build.mutation({
      query: (values) => ({
        url: "auth/signin",
        method: "POST",
        body: values, //
      }),
    }),
    refreshTokenAdmin: build.mutation({
      query: (values) => ({
        url: "auth/refreshtoken",
        method: "POST",
        body: values,
      }),
    }),
    toggleUserStauts: build.mutation({
      query: (values) => ({
        url: "user/togglestatus",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Users"],
    }),
    acceptOrRejectUserAddRequest: build.mutation({
      query: (values) => ({
        url: `admin/process-request/${values.id}`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["UsersCoins"],
    }),
    deleteUser: build.mutation({
      query: (values) => ({
        url: `user/deleteAccount/${values.id}`,
        method: "GET",
      }),
      invalidatesTags: ["UsersCoins"],
    }),
    acceptOrRejectUserPayoutRequest: build.mutation({
      query: (values) => ({
        url: `admin/process-request-payout/${values.id}`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["UsersCoins"],
    }),
    toggleAdminStatus: build.mutation({
      query: (values) => ({
        url: "admin/toggleadminstatus",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Admins"],
    }),
    verifyToken: build.mutation({
      query: (values) => ({
        url: "auth/verifyToken",
        method: "POST",
        body: values,
      }),
    }),
    updateHousesTime: build.mutation({
      query: (values) => ({
        url: "house/time",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Houses"],
    }),
    updateTimers: build.mutation({
      query: (values) => ({
        url: "game/timers",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["timers"],
    }),
    createhouses: build.mutation({
      query: (values) => ({
        url: "house/createhouses",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Houses"],
    }),
    deletehouse: build.mutation({
      query: (values) => ({
        url: "house/deletehouse",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Houses"],
    }),
    edithouse: build.mutation({
      query: (values) => ({
        url: "house/updatehouse",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Houses"],
    }),
    updatecoins: build.mutation({
      query: (values) => ({
        url: "user/updateCoins",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Users"],
    }),
    updateSupportMessage: build.mutation({
      query: ({ id, newMessage }) => ({
        url: `user/updateSupportMessage/${id}`,
        method: "POST",
        body: { newMessage },
      }),
      invalidatesTags: ["supportMessage"],
    }),
  }),
});

export const {
  // useGetUserQuery,
  useGetSupportInfoQuery,
  useGetUsersAddRequestsQuery,
  useGetUsersPayoutRequestsQuery,
  useGetSessionsQuery,
  useGetUsersQuery,
  useGetGamesQuery,
  useGetTransactionsQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetDashboardQuery,
  useGetHousesQuery,
  useVerifyTokenMutation,
  useGetHistoryQuery,
  useGetTimersQuery,
  // post
  useAcceptOrRejectUserAddRequestMutation,
  useAcceptOrRejectUserPayoutRequestMutation,
  useAuthenticateAdminMutation,
  useRefreshTokenAdminMutation,
  useToggleUserStautsMutation,
  useDeleteUserMutation,
  useToggleAdminStatusMutation,
  useUpdateHousesTimeMutation,
  useCreatehousesMutation,
  useDeletehouseMutation,
  useEdithouseMutation,
  useUpdatecoinsMutation,
  useUpdateTimersMutation,
  useUpdateSupportMessageMutation,
} = api;
