import React, { Fragment, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { DateRange } from "react-date-range";
import { parseISO } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useGetTransactionsQuery } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { Download } from "@mui/icons-material";
import * as XLSX from "xlsx";

const Transactions = () => {
  const theme = useTheme();
  const { data, isLoading } = useGetTransactionsQuery();
  const result = data?.data || [];

  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);

  const filteredResults = result.filter((transaction) => {
    if (!dateRange[0].startDate || !dateRange[0].endDate) return true;

    const createdAt = parseISO(transaction.createdAt);
    const endDate = new Date(dateRange[0].endDate);
    endDate.setDate(endDate.getDate() + 1); // Include the full end date

    return createdAt >= dateRange[0].startDate && createdAt < endDate;
  });

  const columns = [
    {
      field: "_id",
      headerName: "Phone Number",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row?.userId?.number || " - "}
          </div>
        );
      },
    },
    {
      field: "username",
      headerName: "User Name",
      flex: 0.5,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row?.userId?.username || " - "}
          </div>
        );
      },
    },

    {
      field: "type",
      headerName: "Reason",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.7,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <div>
            {new Date(params.value).toDateString()}{" "}
            {new Date(params.value).toLocaleTimeString()}
          </div>
        );
      },
    },
  ];

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredResults.map((transaction) => ({
        username: transaction.userId?.username || " - ",
        number: transaction.userId.number || " - ",
        createdAt: `${new Date(transaction.createdAt).toDateString()} -
        ${new Date(transaction.createdAt).toLocaleTimeString()}`,
        reason: transaction.type,
        amount: transaction.amount,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");
    XLSX.writeFile(workbook, `transactions.xlsx`);
  };

  return (
    <Fragment>
      <Box m="1.5rem 2.5rem">
        <Header title="Transactions" subtitle="List of Transactions" />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
        >
          <Button
            type="button"
            variant="contained"
            onClick={downloadExcel}
            sx={{ mt: 2, alignSelf: "flex-end" }}
            startIcon={<Download />}
          >
            Download Excel
          </Button>
          <Button
            variant="contained"
            onClick={() => setIsDateRangeOpen(!isDateRangeOpen)}
          >
            {isDateRangeOpen
              ? "Close Date Range Picker"
              : "Open Date Range Picker"}
          </Button>
          {isDateRangeOpen && (
            <Box
              position="absolute"
              top="100%"
              right="0"
              zIndex="1"
              boxShadow={3}
              bgcolor="background.paper"
            >
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                maxDate={new Date()}
              />
            </Box>
          )}
        </Box>

        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={isLoading}
            getRowId={(row) => row._id}
            rows={filteredResults}
            columns={columns}
            isRowSelectable={false}
            disableSelectionOnClick
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default Transactions;
