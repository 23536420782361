import React, { useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  AccountBalanceWallet,
  AttachMoney,
  People,
  TrendingUp,
} from "@mui/icons-material";
import { Box, Button, useTheme, useMediaQuery } from "@mui/material";
import { DateRange } from "react-date-range";
import { DataGrid } from "@mui/x-data-grid";
import { useGetDashboardQuery } from "state/api";
import StatBox from "components/StatBox";
import { parseISO, isWithinInterval } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const Dashboard = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  // Date range state
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  // Control visibility of DateRange picker
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);

  // Convert date range to params
  const startDate = dateRange[0].startDate
    ? dateRange[0].startDate.toISOString().split("T")[0]
    : null;
  const endDate = dateRange[0].endDate
    ? dateRange[0].endDate.toISOString().split("T")[0]
    : null;

  // Fetch data with optional date range parameters
  const { data, isLoading } = useGetDashboardQuery(
    startDate && endDate
      ? {
          startDate,
          endDate,
        }
      : {}
  );

  const filteredData = data?.result || {};

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
        <Button
          variant="contained"
          onClick={() => setIsDateRangeOpen(!isDateRangeOpen)}
        >
          {isDateRangeOpen
            ? "Close Date Range Picker"
            : "Open Date Range Picker"}
        </Button>
      </FlexBetween>

      {isDateRangeOpen && (
        <Box
          position="relative"
          marginTop="20px"
          display="flex"
          justifyContent="flex-end"
        >
          <Box
            position="absolute"
            zIndex="1"
            boxShadow={3}
            bgcolor="background.paper"
          >
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              maxDate={new Date()}
            />
          </Box>
        </Box>
      )}

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}
        <StatBox
          title="Bonus Coins"
          value={filteredData.bonusCoins || 0}
          icon={
            <AccountBalanceWallet
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        <StatBox
          title="Total Referral Bonus"
          value={filteredData.totalReferralBonus || 0}
          icon={
            <AttachMoney
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        <StatBox
          title="Total Current Coins"
          value={filteredData.totalCurrentCoins || 0}
          icon={
            <AccountBalanceWallet
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        <StatBox
          title="Total Purchased Coins"
          value={filteredData.totalPurchasedCoins || 0}
          icon={
            <AttachMoney
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        <StatBox
          title="Total User Count"
          value={filteredData.totalUserCount || 0}
          icon={
            <People
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        <StatBox
          title="Active User Count"
          value={filteredData.activeUserCount || 0}
          icon={
            <People
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        <StatBox
          title="Total Withdrawal Coins"
          value={filteredData.totalWithdrawalCoins || 0}
          icon={
            <AccountBalanceWallet
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        <StatBox
          title="Admin Winning Amount"
          value={filteredData.adminWinningAmount || 0}
          icon={
            <TrendingUp
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        <StatBox
          title="Admin Losing Amount"
          value={filteredData.adminLossAmount || 0}
          icon={
            <TrendingUp
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
      </Box>
    </Box>
  );
};

export default Dashboard;

//  center

// import React, { useEffect, useState } from "react";
// import io from "socket.io-client";

// const socket = io(process.env.REACT_APP_BASE_URL); // Connect to the server

// function GameTester() {
//   const [playerData, setPlayerData] = useState([]);
//   const [coins, setCoins] = useState(0);
//   const [game_ended, setgame_ended] = useState(false);
//   const [countdown, setCountdown] = useState(null);
//   const [currentTurn, setCurrentTurn] = useState({});
//   const [bidResult, setBidResult] = useState(null);
//   const [roundInProgress, setRoundInProgress] = useState(false);
//   const [revealedNumber, setRevealedNumber] = useState(null);
//   const [distributedNumbers, setDistributedNumbers] = useState({});
//   const [roundStarted, setRoundStarted] = useState(false);

//   useEffect(() => {
//     socket.on("player_added", (data) => {
//       setPlayerData(data.players);
//       setCoins(data.coins);
//     });

//     socket.on("countdown", (data) => {
//       setCountdown(data.countdown);
//     });

//     socket.on("player_turn", (data) => {
//       setCurrentTurn(data);
//     });

//     socket.on("bid_result", (data) => {
//       setBidResult(data);
//     });

//     socket.on("round_in_progress", () => {
//       setRoundInProgress(true);
//     });

//     socket.on("round_started", () => {
//       setRoundStarted(true);
//     });

//     socket.on("number_revealed", (data) => {
//       setRevealedNumber(data.number);
//     });

//     socket.on("initial_numbers_assigned", (data) => {
//       console.log({ data });
//       setDistributedNumbers(data);
//     });
//     socket.on("table_coins", (data) => {
//       console.log({ table_coins: data });
//       setCoins(data?.coins);
//     });
//     socket.on("game_ended", (data) => {
//       console.log({
//         game_ended: true,
//       });
//       setgame_ended(true);
//     });

//     // Cleanup on component unmount
//     return () => {
//       socket.off("player_added");
//       socket.off("countdown");
//       socket.off("player_turn");
//       socket.off("bid_result");
//       socket.off("round_in_progress");
//       socket.off("round_started");
//       socket.off("number_revealed");
//       socket.off("initial_numbers_assigned");
//       socket.off("game_ended");
//     };
//   }, []);

//   const joinGame = () => {
//     socket.emit("join", {
//       category: 10,
//       playerId: "examplePlayerId",
//       playerName: "examplePlayerName",
//     });
//   };

//   const placeBid = () => {
//     socket.emit("bid", {
//       amount: -30, // Example bid amount
//       playerId: "examplePlayerId",
//       type: "bet", // or 'pass'
//     });
//   };

//   const leaveGame = () => {
//     socket.emit("leave", {
//       category: "exampleCategory",
//       playerId: "examplePlayerId",
//     });
//   };

//   return (
//     <div>
//       <h2>Game Tester</h2>
//       <button onClick={joinGame}>Join Game</button>
//       <button onClick={placeBid}>Place Bid</button>
//       <button onClick={leaveGame}>Leave Game</button>

//       <div>Coins: {coins}</div>
//       <div>Countdown: {countdown}</div>
//       <div>
//         Current Turn: Player {currentTurn.playerId}, Time Left:{" "}
//         {currentTurn.remainingTime}
//       </div>
//       {bidResult && (
//         <div>
//           <p>Bid Result:</p>
//           <p>Win: {bidResult.win ? "Yes" : "No"}</p>
//           <p>Coins: {bidResult.coins}</p>
//           <p>Bet Amount: {bidResult.betAmount}</p>
//           <p>Revealed Number: {bidResult.revealedNumber}</p>
//         </div>
//       )}

//       <div>Round In Progress: {roundInProgress ? "Yes" : "No"}</div>
//       <div>Round Started: {roundStarted ? "Yes" : "No"}</div>
//       <div>Revealed Number: {revealedNumber}</div>
//       <div>game_ended: {game_ended}</div>
//       <div>Distributed Numbers:</div>
//       <ul>
//         player {distributedNumbers?.playerId}
//         {distributedNumbers?.numbers?.map((numbers, i) => (
//           <li key={i}> {numbers} </li>
//         ))}
//       </ul>

//       <div>Players:</div>
//       <ul>
//         {playerData.map((player, index) => (
//           <li key={index}>{player.playerName}</li>
//         ))}
//       </ul>
//     </div>
//   );
// }

// export default GameTester;

// titli

// import React, { useEffect, useMemo, useState } from "react";
// import socketIO from "socket.io-client";
// import { base, useGetHistoryQuery } from "state/api";
// import { useSelector } from "react-redux";

// var socket;

// const App = () => {
//   // const { data, loading } = useGetHistoryQuery();

//   const [timeRemaining, setTimeRemaining] = useState({ time: 300 });
//   const global = useSelector((state) => state.global);

//   socket = useMemo(
//     () =>
//       // socketIO.connect(url, {
//       socketIO.connect("http://localhost:5000", {
//         query: { id: global.userId },
//       }),
//     [global.userId]
//   );
//   useEffect(() => {
//     if (!global.userId) return;
//     // socket = socketIO.connect("http://localhost:5000", {
//     //   query: { id: global.userId },
//     // });
//     // const socket = socketIO.connect("https://kinggamez.com");

//     socket.on("connection", (e) => {
//       console.log({ connection: e });
//     });

//     socket.on("timerUpdate", (remainingTime) => {
//       setTimeRemaining(remainingTime);
//     });
//     socket.on("imhere", (remainingTime) => {
//       console.log({ remainingTime });
//     });

//     socket.on("houseDetails", (houseDetails) => {
//       console.log({ houseDetails });
//     });
//     socket.on("history", (history) => {
//       console.log(history);
//     });

//     // Event listener for cron results
//     socket.on("houseResults", (results) => {
//       // Handle cron results from the server
//       console.log(results);
//     });
//     // Event listener for cron results
//     socket.on("mybets", (mybets) => {
//       // Handle cron results from the server
//       console.log(mybets, "mybets");
//     });
//     socket.on("userCoinsAtHistory", (userCoinsAtHistory) => {
//       console.log({ userCoinsAtHistory });
//       socket.emit("getUser", { userId: global.userId });
//     });
//     socket.on("houses", (data) => {
//       console.log({ data });
//     });
//     socket.on("history", (history) => {
//       console.log({ history });
//     });
//     socket.emit("getmybets", { userId: global.userId });
//     socket.on("betResults", (results) => {
//       // Handle cron results from the server
//       console.log(results, "betResults");
//     });
//     socket.on("getUser", (uesrData) => {
//       // Handle cron results from the server
//       console.log({ uesrData });
//     });

//     // Cleanup function on component unmount
//     return () => {
//       // socket.disconnect();
//     };
//   }, []); // Empty dependency array ensures that this effect runs only once
//   const bet = () => {
//     const ids = [
//       "65958499dd9b55417018ca6c",
//       "659584b6dd9b55417018ca72",
//       "65958499dd9b55417018ca6b",
//       "659584b6dd9b55417018ca73",
//     ];
//     const userIDs = ["65a3c04b2c0fa39355714718", "659f725ba2941d70f9844002"];
//     const dataToSend = {
//       // houseId: "65958499dd9b55417018ca6c",
//       // houseId: "65958499dd9b55417018ca6c",
//       // houseId: "65958500dd9b55417018ca81",
//       houseId: ids[parseInt(Math.random() * 3)],
//       betAmount: 2,
//       userId: userIDs[parseInt(Math.random() * 2)],
//     };
//     // const dataToSend = `houseId=659584b6dd9b55417018ca72,betAmount=2,userId=${global.userId}`;
//     socket.emit("housebet", dataToSend);
//     socket.emit("setUserId", {
//       socketId: socket.id,
//       userId: "659f725ba2941d70f9844002",
//     });
//   };
//   return (
//     <div>
//       <h1>Timer: {timeRemaining?.time} seconds</h1>
//       <button onClick={bet}>bet</button>
//       {/* Render other components based on your application logic */}
//     </div>
//   );
// };

// export default App;
