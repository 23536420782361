import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Header from "components/Header";
import { useGetTimersQuery } from "state/api";
import ModalWrapper from "components/ModaWrpper";
import TimersForm from "./TimersForm";

const Stats = ({ name = "", setOpen, data = {} }) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
        cursor: "pointer",
      }}
      onClick={() => {
        setOpen("status");
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div" sx={{ mb: "1rem" }}>
          {name}
        </Typography>
        {Object.keys(data)?.map((field, ind) => {
          return (
            <Typography color={theme.palette.secondary[400]} key={ind}>
              {field}: {data[field]}
            </Typography>
          );
        })}
      </CardContent>
    </Card>
  );
};

const Timers = () => {
  const { data, isLoading } = useGetTimersQuery();

  const result = data?.result;
  const [open, setOpen] = useState(false);
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  if (isLoading) return null;

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="Timers"
        subtitle="See and update your list of game timers."
      />

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          opacity: isLoading ? 0 : 1,
          userSelect: isLoading ? "none" : undefined,
        }}
      >
        <Stats name="Stats" data={data} setOpen={setOpen} />
      </Box>

      <ModalWrapper open={open} setOpen={setOpen}>
        <TimersForm open={open} data={data} setOpen={setOpen} />
      </ModalWrapper>
    </Box>
  );
};

export default Timers;
