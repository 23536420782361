import { useEffect, useMemo } from "react";
import Authenticate from "scenes/Authenticate";
import { useVerifyTokenMutation } from "state/api";
import { useDispatch, useSelector } from "react-redux";
import { setIsTokenVerifed, setTokens } from "state";

export const withAuthorization = (BaseComponent) => {
  return function Authorized(props) {
    const dispatch = useDispatch();
    const tokens = useSelector((state) => state.global.tokens);
    const tokenVerified = tokens.tokenVerified;
    const refreshToken = tokens?.refreshToken;
    const accessToken = useMemo(
      () => tokens?.accessToken,
      [tokens?.accessToken]
    );

    const [verifyToken, { isLoading }] = useVerifyTokenMutation();

    useEffect(() => {
      if (!accessToken) {
      } else if (accessToken && tokens?.accessToken) {
        verifyToken({ refreshToken, accessToken })
          .then(({ data }) => {
            const { accessToken: a, refreshToken: r } = data;
            dispatch(setTokens({ accessToken: a, refreshToken: r }));
            dispatch(setIsTokenVerifed(true));
          })
          .catch((E) => {
            console.log(E);
            dispatch(setIsTokenVerifed(false));
          });
      }
      return () => {
        dispatch(setIsTokenVerifed(false));
      };
    }, []);

    if (isLoading) {
      return null;
    }
    // Check if the token has expired
    if (!tokenVerified || !accessToken) {
      return <Authenticate />;
    } else {
      return <BaseComponent />;
    }
    // HOC-specific logic using hooks
  };
};
