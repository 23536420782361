import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Games from "scenes/games";
import Users from "scenes/users";
import Transactions from "scenes/transactions";
import Admin from "scenes/admin";
import { withAuthorization } from "components/withAuthorization";
import PageNotFound from "components/Error";
import Houses from "scenes/houses";
import Terms from "scenes/terms";
import Privacy from "scenes/privacy";
import Timers from "scenes/Timers";
import Home from "scenes/home/home";
import Sessions from "scenes/sessions";
import Support from "scenes/support";
import RequestCoins from "scenes/requestCoins";
import PayoutCoins from "scenes/payoutCoins";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const SupportwithAuthorization = withAuthorization(Support);
  const UserswithAuthorization = withAuthorization(Users);
  const RequestCoinswithAuthorization = withAuthorization(RequestCoins);
  const PayoutCoinswithAuthorization = withAuthorization(PayoutCoins);
  const SessionswithAuthorization = withAuthorization(Sessions);
  const DashboardwithAuthorization = withAuthorization(Dashboard);
  const TransactionswithAuthorization = withAuthorization(Transactions);
  const GameswithAuthorization = withAuthorization(Games);
  const AdminwithAuthorization = withAuthorization(Admin);
  const HouseswithAuthorization = withAuthorization(Houses);
  const TimersAuthorization = withAuthorization(Timers);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/webadmin" element={<Layout />}>
              <Route
                path="/webadmin"
                element={<Navigate to="/webadmin/dashboard" replace />}
              />
              <Route
                path="/webadmin/dashboard"
                element={<DashboardwithAuthorization />}
              />
              <Route
                path="/webadmin/games"
                element={<GameswithAuthorization />}
              />
              <Route
                path="/webadmin/payout-requests"
                element={<PayoutCoinswithAuthorization />}
              />
              <Route
                path="/webadmin/coins-requests"
                element={<RequestCoinswithAuthorization />}
              />
              <Route
                path="/webadmin/users"
                element={<UserswithAuthorization />}
              />
              <Route
                path="/webadmin/support"
                element={<SupportwithAuthorization />}
              />
              <Route
                path="/webadmin/Sessions"
                element={<SessionswithAuthorization />}
              />
              <Route
                path="/webadmin/transactions"
                element={<TransactionswithAuthorization />}
              />
              <Route
                path="/webadmin/timers"
                element={<TimersAuthorization />}
              />
              <Route
                path="/webadmin/admin"
                element={<AdminwithAuthorization />}
              />
              <Route
                path="/webadmin/houses"
                element={<HouseswithAuthorization />}
              />
              <Route path="*" element={<PageNotFound />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/webadmin/terms" element={<Terms />} />
            <Route path="/webadmin/privacy" element={<Privacy />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
