import React, { useEffect, useState } from "react";
import {
  Box,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Download } from "@mui/icons-material";
import { DateRange } from "react-date-range";
import { parseISO } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  useAcceptOrRejectUserPayoutRequestMutation,
  useGetUsersPayoutRequestsQuery,
} from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { Bounce, toast } from "react-toastify";
import * as XLSX from "xlsx";

const PayoutCoins = () => {
  const theme = useTheme();
  const { data, isLoading } = useGetUsersPayoutRequestsQuery();
  const result = data?.result || [];

  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);

  const filteredResults = result.filter((request) => {
    if (!dateRange[0].startDate || !dateRange[0].endDate) return true;

    const createdAt = parseISO(request.createdAt);
    const endDate = new Date(dateRange[0].endDate);
    endDate.setDate(endDate.getDate() + 1); // Add one day to the end date

    return createdAt >= dateRange[0].startDate && createdAt < endDate;
  });

  const columns = [
    {
      field: "username",
      headerName: "User Name",
      flex: 0.5,
      renderCell: ({ row }) => <>{row?.userId?.username}</>,
      sortable: false,
      filterable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: ({ row }) => <>{row?.userId?.email}</>,
      sortable: false,
      filterable: false,
    },
    {
      field: "number",
      headerName: "Phone Number",
      flex: 1,
      renderCell: ({ row }) => <>{row?.userId?.number || " - "}</>,
      sortable: false,
      filterable: false,
    },
    {
      field: "userCoins",
      headerName: "User Coins",
      flex: 0.7,
      renderCell: ({ row }) => <>{row?.amount}</>,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.6,
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {new Date(params.value).toDateString()}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action Date",
      flex: 0.6,
      sortable: false,
      minWidth: 200,
      renderCell: (params) => {
        const value = params.value;
        const displayValue = value ? new Date(value).toLocaleString() : "-";
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {displayValue}
          </div>
        );
      },
    },
    {
      field: " ",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      renderCell: ({ row }) => {
        return <ActionButtons id={row?._id} data={row} />;
      },
    },
  ];

  const ActionButtons = ({ id, data }) => {
    const [open, setOpen] = useState(false);
    const [actionType, setActionType] = useState("");
    const [processRequest, { isLoading: requestLoading, data: responseData }] =
      useAcceptOrRejectUserPayoutRequestMutation();

    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      if (responseData?.type === "success") {
        handleClose();
        toast.success(responseData?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      }
    }, [responseData]);

    const handleClickOpen = (type) => {
      setActionType(type);
      setOpen(true);
    };

    const handleSubmit = async () => {
      console.log(`${actionType} submitted for ID ${id}`);
      await processRequest({ id: id, action: actionType });
    };

    return (
      <>
        {data?.processed === false ? (
          <>
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              width="100%"
              gap={1}
            >
              <Button
                variant="contained"
                sx={{ backgroundColor: "#22bb33" }}
                color="primary"
                onClick={() => handleClickOpen("approve")}
              >
                Accept
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "white",
                  backgroundColor: "#ff0505",
                }}
                onClick={() => handleClickOpen("reject")}
              >
                Reject
              </Button>
            </Box>
          </>
        ) : data?.approved ? (
          "Approved"
        ) : (
          "Declined"
        )}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{actionType}</DialogTitle>
          <DialogContent sx={{ borderRadius: "30px" }}>
            <DialogContentText>
              Are you sure you want to {actionType.toLowerCase()} this request?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ borderColor: "white", color: "white" }}
              variant="outlined"
              onClick={handleClose}
              disabled={requestLoading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              color="primary"
              disabled={requestLoading}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const exportToExcel = (apiData, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(
      apiData.map((item) => ({
        Username: item.userId?.username,
        Email: item.userId?.email,
        Phone: item.userId?.number || "-",
        UserCoins: item.amount,
        CreatedAt: new Date(item.createdAt).toDateString(),
        Status: item.processed
          ? item.approved
            ? "Approved"
            : "Declined"
          : "Pending",
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Requests");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const handleDownload = () => {
    exportToExcel(filteredResults, "PayoutCoinsRequests");
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <Header
          title="Payout Requested Coins"
          subtitle="List of Payout Request Coins"
        />
        <Button
          variant="contained"
          onClick={() => setIsDateRangeOpen(!isDateRangeOpen)}
        >
          {isDateRangeOpen
            ? "Close Date Range Picker"
            : "Open Date Range Picker"}
        </Button>
        {isDateRangeOpen && (
          <Box
            position="absolute"
            top="100%"
            right="0"
            zIndex="1"
            boxShadow={3}
            bgcolor="background.paper"
          >
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              maxDate={new Date()}
            />
          </Box>
        )}
      </Box>

      <Button
        type="button"
        variant="contained"
        onClick={handleDownload}
        sx={{ mt: 2, alignSelf: "flex-end" }}
        startIcon={<Download />}
      >
        Download Excel
      </Button>

      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          getRowId={(row) => row._id}
          rows={filteredResults}
          columns={columns}
          isRowSelectable={false}
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default PayoutCoins;
