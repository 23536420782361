import React, { useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Stack,
  styled,
  FormLabel,
} from "@mui/material";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { SignupBtn } from "scenes/Authenticate";
import { useUpdateHousesTimeMutation } from "state/api";
import dayjs from "dayjs";

const statusOptions = ["active", "inactive"];
const LabelForInput = styled(FormLabel)(({ theme }) => ({
  color: "black",
  fontSize: "1.2rem",
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
}));

const DateTimePickerStyled = styled(DateTimePicker)(({ theme }) => ({
  color: "black",
  "& input, svg": {
    borderColor: "black",
    color: "black",
  },
  "fieldset,  fieldset:hover": {
    borderColor: "black !important",
  },
}));
const SelectdStyled = styled(Select)(({ theme }) => ({
  color: "black",
  "& input": {
    borderColor: "black",
    color: "black",
  },
  fieldset: {
    borderColor: "black !important",
  },
}));
const StatusForm = ({ houses, setOpen }) => {
  const [formData, setFormData] = useState({
    startDate: houses?.startDate || "",
    endDate: houses?.endDate || "",
    status: houses?.active ? "active" : "inactive",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [updateHousesTime, { isLoading }] = useUpdateHousesTimeMutation();
  const getIsDate = (input) => {
    if (dayjs.isDayjs(input)) {
      // If it's a Dayjs object, convert to date string in ISO format
      return input.toISOString();
    } else {
      // If it's not a Dayjs object, treat it as a date string
      const date = new Date(input);
      return !isNaN(date.getTime()) ? date.toISOString() : null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const startDate = getIsDate(formData.startDate);
      const endDate = getIsDate(formData.endDate);
      const status = formData.status;

      if (!status || (!startDate && !endDate) || isLoading) {
        return;
      }

      await updateHousesTime({ startDate, endDate, status });
      setOpen(false);
    } catch (e) {
      console.log("Error");
      setOpen(true);
    }
  };
  return (
    <form>
      <Stack spacing={2} direction="column" sx={{ bg: "black" }}>
        <LabelForInput>Start Date</LabelForInput>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateTimePicker"]}>
            <DateTimePickerStyled
              //   views={["year", "month", "day", "hours", "minutes", "seconds"]}
              value={dayjs(formData.startDate)}
              minutesStep={1}
              onChange={(newValue) => {
                setFormData((e) => {
                  return { ...e, startDate: newValue };
                });
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
        <LabelForInput>End Date</LabelForInput>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateTimePicker"]}>
            <DateTimePickerStyled
              //   views={["year", "month", "day", "hours", "minutes", "seconds"]}
              value={dayjs(formData.endDate)}
              minutesStep={1}
              onChange={(newValue) => {
                setFormData((e) => {
                  return { ...e, endDate: newValue };
                });
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
        <LabelForInput>Status</LabelForInput>

        <FormControl fullWidth sx={{ color: "black" }}>
          {/* <InputLabel id="status-label">Status</InputLabel> */}
          <SelectdStyled
            label={null}
            name="status"
            labelId="status-label"
            placeholder="Status of House"
            value={formData.status}
            onChange={handleChange}
            sx={{ color: "black", borderColor: "black" }}
          >
            {statusOptions.map((status) => (
              <MenuItem color="black" key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </SelectdStyled>
        </FormControl>
        <SignupBtn
          type="submit"
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Submit
        </SignupBtn>
      </Stack>
    </form>
  );
};

export default StatusForm;
