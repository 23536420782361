import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  useMediaQuery,
  Grid,
  CircularProgress,
  Stack,
  Pagination,
  Button,
} from "@mui/material";
import { DateRange } from "react-date-range";
import { parseISO } from "date-fns";
import Header from "components/Header";
import { useGetSessionsQuery } from "state/api";
import ModalWrapper from "components/ModaWrpper";
import TimersForm from "./TimersForm";
import { convertTimestampToDateAndTime } from "state/functions";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const Stats = ({ name = "", setOpen, data = {}, setLocalData }) => {
  const theme = useTheme();

  const betAmount = data?.users?.reduce(
    (total, user) => total + user?.amount,
    0
  );
  const winningAmount = betAmount - data?.totalLosses;

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
        cursor: "pointer",
      }}
      onClick={() => {
        setOpen("status");
        setLocalData(data);
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5" component="div" sx={{ mb: "1rem" }}>
              Winner House: {data?.winnerHouseName}
            </Typography>
            <Typography variant="h5" component="div" sx={{ mb: "1rem" }}>
              Total Users: {data?.totalUniqueUsers}
            </Typography>
            <Typography variant="h5" component="div" sx={{ mb: "1rem" }}>
              Total Winners: {data?.totalWinners}
            </Typography>
            <Typography variant="h5" component="div" sx={{ mb: "1rem" }}>
              Total Winning Amount: {winningAmount}
            </Typography>
            <Typography variant="h5" component="div" sx={{ mb: "1rem" }}>
              Admin Losing Amount: {data?.totalWinnings}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" component="div" sx={{ mb: "1rem" }}>
              Session Time : {convertTimestampToDateAndTime(data?.createdAt)}
            </Typography>
            <Typography variant="h5" component="div" sx={{ mb: "1rem" }}>
              Total Bet Amount: {betAmount}
            </Typography>
            <Typography variant="h5" component="div" sx={{ mb: "1rem" }}>
              Total Losers: {data?.totalLosers}
            </Typography>
            <Typography variant="h5" component="div" sx={{ mb: "1rem" }}>
              Admin Amount: {data?.totalLosses}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              maxHeight: "200px",
              overflowY: "auto",
              padding: "8px",
              backgroundColor: "#515989",
            }}
          >
            {data?.houses?.map((user, index) => (
              <Box key={index} sx={{ marginBottom: "8px" }}>
                <Typography variant="body1">
                  House Name: {user.houseName}
                </Typography>
                <Typography variant="body1">Amount: {user.amount}</Typography>
                <Typography variant="body1">
                  Status: {user.isWinner ? "Winner" : "Loser"}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Sessions = () => {
  const { data, isLoading } = useGetSessionsQuery();
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
  const itemsPerPage = 5;
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  const handleChange = (event, value) => {
    setPage(value);
  };

  const filteredData = data?.data?.filter((session) => {
    if (!dateRange[0].startDate || !dateRange[0].endDate) return true;

    const createdAt = parseISO(session.createdAt);
    const endDate = new Date(dateRange[0].endDate);
    endDate.setDate(endDate.getDate() + 1);

    return createdAt >= dateRange[0].startDate && createdAt < endDate;
  });

  const count = Math.ceil(filteredData?.length / itemsPerPage);

  const currentData = filteredData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const [open, setOpen] = useState(false);
  const [localData, setLocalData] = useState([]);

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Sessions" subtitle="List of sessions" />

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        position="relative"
      >
        <Button
          variant="contained"
          onClick={() => setIsDateRangeOpen(!isDateRangeOpen)}
        >
          {isDateRangeOpen
            ? "Close Date Range Picker"
            : "Open Date Range Picker"}
        </Button>
        {isDateRangeOpen && (
          <Box
            position="absolute"
            top="100%"
            right="0"
            zIndex="1"
            boxShadow={3}
            bgcolor="background.paper"
          >
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              maxDate={new Date()}
            />
          </Box>
        )}
      </Box>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(1, minmax(0, 1fr))"
        justifyContent="space-between"
        rowGap="20px"
        columnGap="1.33%"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          opacity: isLoading ? 0 : 1,
          userSelect: isLoading ? "none" : undefined,
        }}
      >
        <Stack
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ py: "30px" }}
        >
          <Pagination count={count} page={page} onChange={handleChange} />
        </Stack>
        {currentData?.map((item) => (
          <Stats
            key={item.id}
            name="Stats"
            data={item}
            setOpen={setOpen}
            setLocalData={setLocalData}
          />
        ))}
        <Stack
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ py: "30px" }}
        >
          <Pagination count={count} page={page} onChange={handleChange} />
        </Stack>
      </Box>
    </Box>
  );
};

export default Sessions;
