import React, { Fragment, useState } from "react";
import { Box, useTheme, MenuItem, Menu } from "@mui/material";
import { useGetAdminsQuery, useToggleAdminStatusMutation } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { MoreVert } from "@mui/icons-material";

const Admin = () => {
  const theme = useTheme();
  const { data, isLoading } = useGetAdminsQuery();
  const result = data?.result || [];

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "username",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.7,
    },
    {
      field: "hidden",
      headerName: "Hidden",
      flex: 0.4,
    },

    {
      field: "createdAt",
      headerName: "created At",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {new Date(params.value).toDateString()}
          </div>
        );
      },
    },
    {
      field: " ",
      headerName: "Edit",
      flex: 0.4,
      renderCell: (params) => {
        return (
          <div
            onClick={(e) => {
              handleClick(e);
              setStatus(params.row?._id);
            }}
          >
            <MoreVert sx={{ cursor: "pointer" }} />
          </div>
        );
      },
    },
  ];

  // anchor for menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setStatus(null);
  };
  const [toggleAdminStatus, { isLoading: loadingStatus }] =
    useToggleAdminStatusMutation();
  const handleStatus = async () => {
    try {
      const data = await toggleAdminStatus({ _id: status });
      console.log("called", data);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Fragment>
      <Box m="1.5rem 2.5rem">
        <Header title="Admins" subtitle="List of Admins" />
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={isLoading || loadingStatus}
            getRowId={(row) => row._id}
            rows={result}
            columns={columns}
            isRowSelectable={false}
            disableSelectionOnClick
          />
        </Box>
      </Box>
      {anchorEl ? (
        <CustomMenu
          anchorEl={anchorEl}
          handleStatus={handleStatus}
          open={open}
          onClick={handleClose}
        />
      ) : null}
    </Fragment>
  );
};

export default Admin;

const CustomMenu = ({ anchorEl, onClick, open, handleStatus }) => {
  return (
    <div style={{ postition: "relative" }}>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        <MenuItem onClick={() => handleStatus()}>Toggle Status</MenuItem>
      </Menu>
    </div>
  );
};
